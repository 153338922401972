<template>
	<div class="strict" v-title="'严选建材——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<NavBar></NavBar>
		<div class="box">
			<img class="bran" src="../image/build/header_pc.jpg" alt="" />
		</div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/build/header_mob.jpg"
				alt=""
			/>
		</div>
		<div class="content f-y-s-c">
			<div class="box1">
				<img class="img1" src="../image/build/box1.jpg" alt="" />
			</div>
            <div class="box1-mob">
                <div class="header f-y-c-c">
					<span class="text1">全球品牌建材</span>
					<span class="text2"
						>专业材料甄选团队，严格筛选更安全环保</span
					>
				</div>
				<img class="img1" src="../image/build/box1_mob.png" alt="" />
			</div>
			<div class="box2 f-y-s-c">
				<div class="header f-y-c-c">
					<span class="text1">豪华建材配置</span>
					<span class="text2"
						>每一个品牌都代表了一种生活方式，千家美严选供应链，企业签约采购，让品质生活更简单</span
					>
				</div>
				<div class="box-list">
					<img
						v-for="(item, index) in brandList"
						:key="index"
						:src="item.path"
						alt=""
						class="item"
                        :class="{disitem:index>17}"
					/>
				</div>
			</div>
			<div class="box3 f-y-c-c">
				<div class="tab-list f-x-b-c">
					<div
						v-for="(item, index) in tabList"
						:key="index"
						class="list-item f-x-c-c"
						:class="curTab == index ? 'tab-active' : ''"
						@click="cahngeTabs(index)"
					>
						<img class="img3" :src="item.icon" alt="" />
					</div>
				</div>
				<div class="banner">
					<img class="img4" :src="tabList[curTab].url" alt="" />
					<div class="info f-y-s-s">
						<span class="text3">{{ tabList[curTab].title }}</span>
						<span class="text4">{{ tabList[curTab].form }}</span>
						<p class="text5">
							{{ tabList[curTab].desc }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
export default {
	components: {
		NavBar,
		Footer,
	},
	created() {},
	mounted() {},
	data() {
		return {
			brandList: [
				{
					path: require('../image/common/brand1.jpg'),
				},
				{
					path: require('../image/common/brand2.jpg'),
				},
				{
					path: require('../image/common/brand3.jpg'),
				},
				{
					path: require('../image/common/brand4.jpg'),
				},
				{
					path: require('../image/common/brand5.jpg'),
				},
				{
					path: require('../image/common/brand6.jpg'),
				},
				{
					path: require('../image/common/brand7.jpg'),
				},
				{
					path: require('../image/common/brand8.jpg'),
				},
				{
					path: require('../image/common/brand9.jpg'),
				},
				{
					path: require('../image/common/brand10.jpg'),
				},
				{
					path: require('../image/common/brand11.jpg'),
				},
				{
					path: require('../image/common/brand12.jpg'),
				},
				{
					path: require('../image/common/brand13.jpg'),
				},
				{
					path: require('../image/common/brand14.jpg'),
				},
				{
					path: require('../image/common/brand15.jpg'),
				},
				{
					path: require('../image/common/brand16.jpg'),
				},
				{
					path: require('../image/common/brand17.jpg'),
				},
				{
					path: require('../image/common/brand18.jpg'),
				},
				{
					path: require('../image/common/brand19.jpg'),
				},
				{
					path: require('../image/common/brand20.jpg'),
				},
			],
			curTab: 0,
			tabList: [
				{
					value: 1,
					icon: require('../image/price/box6_icon1.jpg'),
					url: require('../image/price/box6_img1.jpg'),
					title: '菲林格尔 ',
					form: '始创于1921年·德国',
					desc: '菲林格尔成立于德国巴登符腾堡州。九十余年来，菲林格尔逐渐发展为欧洲最大的旅行车内家具制造商，是梅赛德斯·奔驰等著名汽车公司车内木制品的供货商和著名家具中间产品供货商。',
				},
				{
					value: 2,
					icon: require('../image/price/box6_icon2.jpg'),
					url: require('../image/price/box6_img2.jpg'),
					title: '阿尔贝娜',
					form: 'Alpina品牌成立于1909年',
					desc: '专注于消费者涂料市场，品牌源自DAW SE第一代创始人在阿尔卑斯山度假时，被雪山白色的美景吸引，萌发了要研制出一款像雪山一样白的装饰涂料。',
				},
				{
					value: 3,
					icon: require('../image/price/box6_icon3.jpg'),
					url: require('../image/price/box6_img3.jpg'),
					title: '索菲亚',
					form: 'SOGAL源自法国1981年',
					desc: '索菲亚一直专注柜类定制家具，产品包含衣柜、书柜、电视柜等定制家具。索菲亚板材环保超越欧标，板材曾获美国环保署NAF环保认证和通过瑞士SGS机构严苛测定。',
				},
				{
					value: 4,
					icon: require('../image/price/box6_icon4.jpg'),
					url: require('../image/price/box6_img4.jpg'),
					title: '大金',
					form: '自1924年成立以来',
					desc: '始终致力于探索健康、舒适空气的奥秘。作为一家集空调、冷媒、压缩机的研发、生产、销售、售后服务位一体的世界知名企业。',
				},
				{
					value: 5,
					icon: require('../image/price/box6_icon5.jpg'),
					url: require('../image/price/box6_img5.jpg'),
					title: '慕思',
					form: 'DeRUCCI 品牌创立于2004年',
					desc: '致力于人体健康睡眠研究，专业从事整套健康睡眠系统的研发、生产和销售，在业内创新打造了“量身定制个人专属的健康睡眠系统”。',
				},
				{
					value: 6,
					icon: require('../image/price/box6_icon6.jpg'),
					url: require('../image/price/box6_img6.jpg'),
					title: '卫水宝',
					form: '世界领先的PP-R 管道产品制造商之一',
					desc: 'VESBO(卫水宝)公司是一家在热塑管工业领域一直处于领先地位的制造商。VESBO不仅为室内给排水提供了解决的方法，在垂直，加热,污水处理等领域中更是佼佼者。',
				},
			],
		}
	},
	methods: {
		cahngeTabs(index) {
			this.curTab = index
		},
	},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .strict {
        overflow-x: hidden;
		.box {
            display: none;
			
		}
        .box-mob{
            width: 100%;
			height: 200px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .content {
			// padding: 0 180px 70PX 180PX;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #ffffff;
            .box1{
                display: none;
            }
            .box1-mob{
                background: center/cover url(../image/build/box1_bg.jpg);
                padding: 50px 0  50px 0;
                width: 100%;
                .header{
                    padding: 0 30px;
                    .text1{
                        
                        font-weight: bold;
                        font-size: 24px;
                        color: #120523;
                    }
                    .text2{
                        text-align: center;
                        margin-top: 5px;
                        
                        font-weight: 400;
                        font-size: 12px;
                        color: #444444;
                    }
                }
                .img1{
                    padding: 0 12px;
                    box-sizing: border-box;
                    margin-top: 20px;
                    width: 100%;
                    height: 245px;
                    object-fit: cover;
                }
            }
            .box2{
                margin: -50px 12px 0 12px;
                padding: 12px;
                width: calc(100% - 24px);
                // height: 860px;
                box-sizing: border-box;
                border-radius: 9px;
                background:no-repeat center/cover url(../image/build/box2_bg.jpg);
                box-shadow: 0px 10px 15px 0px rgba(4,0,0,0.1);
                .header{
                    padding: 0 30px;
                    .text1{
                        
                        font-weight: bold;
                        font-size: 24px;
                        color: #120523;
                    }
                    .text2{
                        text-align: center;
                        margin-top: 5px;
                        
                        font-weight: 400;
                        font-size: 12px;
                        color: #444444;
                    }
                }
                .box-list{
                    width: 100%;
                    // display: flex;
                    // flex-wrap: wrap;
                    margin-top: 15px;
                    display: grid;
                    grid-template-columns: repeat(3,1fr);
                    gap: 5px 20px;
                    .item{
                        width: 100%;
                        height: 40px;
                        background: #FFFFFF;
                        border: 1px solid #E7E7E7;
                        // margin-bottom: 10px;
                    }
                    .disitem{
                        display: none;
                    }
                }
            }
            .box3{
                width: 100%;
                margin-top: 50px;
                .tab-list{
                    width: 100%;
                    padding: 0 10px;
                    box-sizing: border-box;
                    .list-item{
                        width: 50px;
                        height: 50px;
                        background: #FFFFFF;
                        box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);
                        border-radius: 50%;
                        overflow: hidden;
                        cursor: pointer;
                        box-sizing: border-box;
                        // border: 3px solid #7840BF;
                        .img3{
                            width: 40px;
                            height: 30px;
                            object-fit: cover;
                        }
                    }
                    .tab-active{
                        border: 1px solid #7840BF;
                    }
                }
                .banner{
                    margin-top: 18px;
                    width: 100%;
                    height: 300px;
                    position: relative;
                    .img4{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .info{
                        position: absolute;
                        top:50px;
                        right: 0;
                        width: 250px;
                        height: 200px;
                        background: #FFFFFF;
                        border-radius:9px 0 0 9px ;
                        opacity: 0.98;
                        padding: 30px 15px 15px 15px;
                        box-sizing: border-box;
                        .text3{
                            
                            font-weight: bold;
                            font-size: 20px;
                            color: #120523;
                            line-height: 20px;
                        }
                        .text4{
                            
                            font-weight: bold;
                            font-size: 16px;
                            color: #120523;
                            line-height: 18px;
                            margin-top: 10px;
                        }
                        .text5{
                            
                            font-weight: 400;
                            font-size: 12px;
                            color: #444444;
                            line-height: 16px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 900px) {
    .strict{
        .box{
            width: 100%;
			height: 420px;
			// margin-top: 96px;
            .bran {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .box-mob{
            display: none;
        }
        .content {
			// padding: 0 180px 70PX 180PX;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #ffffff;
            .box1{
                width: 100%;
                height: 1587px;
                .img1{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .box1-mob{
                display: none;
            }
            .box2{
                margin: -290px auto 0 auto;
                padding: 70px 60px;
                width: 1554px;
                // height: 860px;
                box-sizing: border-box;
                border-radius: 18px;
                background: url(../image/build/box2_bg.jpg);
                background-size: 100% 100%;
                background-repeat: no-repeat;
                box-shadow: 0px 10px 15px 0px rgba(4,0,0,0.1);
                .header{
                    .text1{
                        
                        font-weight: bold;
                        font-size: 72px;
                        color: #120523;
                    }
                    .text2{
                        margin-top: 25px;
                        
                        font-weight: 400;
                        font-size: 30px;
                        color: #444444;
                    }
                }
                .box-list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 50px;
                    .item{
                        width: 270px;
                        height: 120px;
                        background: #FFFFFF;
                        border: 1px solid #E7E7E7;
                        margin-bottom: 20px;
                    }
                }
            }
            .box3{
                width: 100%;
                margin-top: 130px;
                .tab-list{
                    width: 100%;
                    padding: 0 290px;
                    box-sizing: border-box;
                    .list-item{
                        width: 171px;
                        height: 171px;
                        background: #FFFFFF;
                        box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);
                        border-radius: 50%;
                        overflow: hidden;
                        cursor: pointer;
                        box-sizing: border-box;
                        // border: 3px solid #7840BF;
                        .img3{
                            width: 120px;
                            height: 90px;
                        }
                    }
                    .tab-active{
                        border: 3px solid #7840BF;
                    }
                }
                .banner{
                    margin-top: 36px;
                    width: 100%;
                    height: 1100px;
                    position: relative;
                    .img4{
                        width: 100%;
                        height: 100%;
                    }
                    .info{
                        position: absolute;
                        top: 245px;
                        right: 220px;
                        width: 760px;
                        height: 610px;
                        background: #FFFFFF;
                        border-radius: 19px;
                        opacity: 0.98;
                        padding: 90px;
                        box-sizing: border-box;
                        .text3{
                            
                            font-weight: bold;
                            font-size: 64px;
                            color: #120523;
                            line-height: 64px;
                        }
                        .text4{
                            
                            font-weight: bold;
                            font-size: 48px;
                            color: #120523;
                            line-height: 55px;
                            margin-top: 33px;
                        }
                        .text5{
                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #444444;
                            line-height: 30px;
                            margin-top: 39px;
                            line-height: 48px;
                        }
                    }
                }
            }
        }
    }
}
</style>
